.slider {
    -webkit-appearance: none;
    height: 12px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 75%;
    background: #b7791f;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 75%;
    background: #b7791f;
    cursor: pointer;
  }  
  .header-style {
    width: 97.5%;
    padding-left: 5px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 320px) {
    .ProductCategory {
       min-width: 100%;
    }
  }